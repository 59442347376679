import store from "@/store";

/**
 * Check if user has access to current page
 * @param {string} page - current page
 */
function checkAccess(page) {
    const loggedIn = store.getters['user/loggedIn'];
    location.page = page;
    let redirect = '';
    
    if (page === 'admin' || page === 'admin-trainings') {
        const userRole = store.getters['user/userRole'];

        if (userRole !== 14 && userRole !== 13) {
            redirect = '/'
        }
    }
    // redirect out of auth pages is user is logged
    else if (page === 'login' || page === 'reset' || page === 'registration' || page === 'newPassword') {
        if (loggedIn) {
            redirect = '/courses'
        }
    } else if (page === 'index' || page === 'about' || page === 'privacyPolicy' || page === 'competitionRules' || page === 'personalData' || page === '404' || page === 'articleList' || page === 'articleDetail' || page === 'ms365' || page === 'powerAutomate' || page === 'msAzure' || page === 'powerBi' || page === 'cyberSecurity' || page === 'students' || page === 'mentions' || page === 'studyProgram' || page === 'digistart' || page === 'lectures' || page === 'lectureDetail') {
        // do nothing on index
    } else {
        // redirect out pages is user is not logged
        if (!loggedIn) {
            redirect = '/login'
        }
    }

    if (redirect) {
        window.location.href = redirect
    } else {
        store.dispatch('page/setPage', page)
        store.dispatch('page/setCurrentPage', getCurrentPage(page))
    }
}

/**
 * Save page id from html attr to store
 * Add id to page name
 *
 * @param {String} page - page name
 * @return {string} - page name with id
 * */
function getCurrentPage(page) {
    let pageId = null;
    let currentPage = page;
    const appMainElement = document.querySelector('#app');

    // Change page name for dynamic pages to show active page in navigations
    if (page === 'video') {
        pageId = appMainElement.dataset.course_id;
    } else if (page === 'assessment') {
        pageId = appMainElement.dataset.assessment_id;
    } else if (page === 'articleDetail') {
        pageId = appMainElement.dataset.article_id;
    } else if (page === 'studyProgram') {
        pageId = appMainElement.dataset.study_program_id;
    }

    // convert to integer
    if (pageId) {
        pageId = parseInt(pageId)
    }

    // save page id to store
    if (pageId) {
        store.dispatch('page/setPageId', pageId)
        currentPage += pageId;
    }

    return currentPage;
}


export const authorizationService = {
    checkAccess,
};